import React from "react";
import { Link } from "gatsby";
import { WelcomeBonusQuery } from "../../prismic/staticQueries/markets/index.js";
import Layout from "../../layout";
import { Subscription, BreadCrumbBanner } from "../../components/common";
import "react-multi-carousel/lib/styles.css";
import { connect, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { PrismicRichText } from "@prismicio/react";
import Carousel from "react-multi-carousel";
import CountUp from "react-countup";
import { Accordion } from "react-bootstrap";
import banner_image from "../../images/siteImages/home/welcome_bonus.png";
import markets_side_image from "../../images/siteImages/home/bonus-30.png";
//import markets_side_image from "../../images/siteImages/StocksIcons/amazon_icon.png";
import register from "../../images/siteImages/home/register.svg";
import introduce from "../../images/siteImages/home/join-chain.svg";
import earn from "../../images/siteImages/home/earn-money.svg";
import riskfree from "../../images/siteImages/home/riskfree.svg";
import PaymentSlider from "./PaymentSlider.js";

const WelcomeBonus = () => {
  const language = useSelector((state) => state.language);
  const WelcomeBonusData = WelcomeBonusQuery(language);
  console.log("language", WelcomeBonusData);
  const {
    welcome_heading,
    welcome_content,
    demo_link,
    demo_link_text,
    faq_heading,
    all_markets_box,
    // markets_side_image,
    analytic_head,
    three_box,
    questionsbox
  } = WelcomeBonusData;

  const all_markets_icon = [
    register,
    introduce,
    earn,
    riskfree
  ];
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
      slidesToSlide: 4 
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 2
    },
  };

  return (

    <Layout >
      <BreadCrumbBanner banner_image={banner_image} />
      <section className="sectionbg pb-5">
        <Container className="">
          <Row className="py-5">
            <Col lg={4}>
              <h2 className="pt-5 my-3 text-bold-md text-black">{welcome_heading.text}</h2>
              <p className="text-gray my-3 pb-5">
                {welcome_content.text}</p>
              <Link to={demo_link.text} className="demolink">
                {demo_link_text.text}
              </Link>
            </Col>
            <Col lg={3}></Col>
            <Col lg={4} className="py-5">
              <img alt="ddd" src={markets_side_image} className="dubai-img" />
            </Col>
          </Row>
          <Row className="mt-5">
            {all_markets_box.length > 0 && all_markets_box.map((marketmap, marketmapInd) => (
              <Col className="mb-5"
                lg={3}
                md={6} key={"marketmapInd" + marketmapInd}>
                <div className="partner-card h-100 mb-4 mx-0 py-2 px-1">
                  <div className="d-flex flex-column mb-2 align-items-center">
                    <div className="box-icon-1 mb-4">
                      <img alt="ddd" src={all_markets_icon[marketmapInd]} className="icon-width" />
                    </div>
                    <h6 className="text-center mb-0"> {marketmap.all_market_description.text}</h6>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <section className="gradient-bg">
        <Container className="py-5">
          <Row>
            <Col md={12} lg={12}>
              <h3 className="text-white text-center">{analytic_head.text}</h3>
            </Col>
          </Row>
          <div className="d-flex py-3 justify-content-center">
            {three_box.length > 0 && three_box.map((threemap, threemapInd) => (
              <div className="three-card px-3" key={"threemapInd" + threemapInd}>
                <div className="h-100">
                  <div className="d-flex flex-column align-items-center">
                    <h6 className="mb-3 text-center"> {threemap.three_heading.text}</h6>
                    <h5>{threemap.three_description.text}</h5>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Container>
      </section>
      <section className="sectionbg py-5">
        <Container className="">
          <div className="accordion-styles accordion--one">
            <div className="container">
              <div className="row">
                <h2 className="pt-2 text-bold-md heading text-center">{faq_heading.text}</h2>
                <div className="col-lg-12">
                  <div className="accordion accordion_one" id="accordion_one">
                    <div className="accordion-single">
                      <Accordion defaultActiveKey={["0"]} alwaysOpen>
                        {questionsbox.map((item, index) => (
                          <Accordion.Item key={`acc-${index}`} eventKey={index}>
                            <Accordion.Header>
                              {item.question.text}
                            </Accordion.Header>
                            <Accordion.Body>{item.answer.text}</Accordion.Body>
                          </Accordion.Item>
                        ))}
                      </Accordion>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <PaymentSlider/>
    </Layout>
  );
};
const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(WelcomeBonus);